<!--
 * @Date: 2022-08-16 10:32:02
 * @LastEditors: 温富杰 wenfujie@dianchu.com
 * @LastEditTime: 2024-11-21 09:28:36
 * @FilePath: /dc-pay-front/src/views/Favorite/index.vue
-->
<template>
  <div style="overflow: hidden">
    <!-- 加载中 -->
    <van-loading
      style="
        text-align: center;
        padding-top: 1rem;
        background-color: transparent;
      "
      type="spinner"
      v-if="loading.list"
    />

    <!-- 列表为空展示 -->
    <van-empty
      v-else-if="isEmptyList"
      class="custom-image"
      :image="require('@/assets/empty/cart_empty.png')"
    >
      <slot name="description">
        <p class="empty-text">{{ $t("fav.emptyTip1") }}</p>
        <p class="empty-text">{{ $t("fav.emptyTip2") }}</p>
      </slot>
    </van-empty>

    <ul v-else class="list">
      <li v-for="item in favoriteList" :key="item.id">
        <WhiteCard style="padding: 0.20rem 0.32rem; border-radius: 0.08rem">
          <div class="item-title" @click="openEditPop(item)">
            <span>{{ item.name }}</span>
            <img :src="require('@/assets/icon/icon_edit.svg')" />
          </div>
          <div
            @click="openDetailPop(item.id)"
            class="fav-item-bg"
            :style="{ backgroundImage: `url(${favoriteBgImageUrl})` }"
          >
            <div class="icon-check"></div>
            <p class="common-price price-style">
              {{ item.amount_display }} {{ item.currency }}
            </p>
            <div class="flex-vcenter">
              <Stepper
                class="favorite-stepper"
                v-if="!withoutShoppingCartMode"
                v-model="item.quantity"
                @change="changeAmount(arguments[0], item)"
              ></Stepper>
              <div
                class="circle-btn"
                @click.stop="deleteApi(item.id)"
                style="width: .44rem; height: .44rem; margin-left: 0.24rem"
              >
                <van-icon :name="require('@/assets/icon/icon_delete.svg')" size=".44rem" />
              </div>
            </div>
          </div>
        </WhiteCard>
      </li>
    </ul>

    <EditPopup
      ref="editPopRef"
      :editType="EDIT_TYPE_ENUM.edit"
      :favoriteInfo="currFavoriteItem"
      @updateSuc="getFavorites"
    />
    <ProductGroupDetailPopup
      ref="detailPopRef"
      :groupInfo="detailInfo.group"
      :products="detailInfo.products"
      @addCart="addCartByDetailPop"
      @clickBuy="clickBuyByDetailPop"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { Empty } from 'vant'
import EditPopup, {
  EDIT_TYPE_ENUM
} from '@/views/Favorite/popup/EditPopup.vue'
import ProductGroupDetailPopup from '@/components/ProductGroupDetailPopup.vue'
import WhiteCard from '@/components/WhiteCard.vue'
import Stepper from '@/components/Stepper.vue'
import {
  validateBeforeUpdateBatchCartCount,
  sendTrackAddCartCommon
} from '@/utils/business/cart.js'
import { getVersionByParseUrl } from '@/utils/business.js'
import {
  sendFavAndRecommandTrack,
  OperatorRecord
} from '@/utils/business/product.js'

export default {
  components: {
    EditPopup,
    ProductGroupDetailPopup,
    [Empty.name]: Empty,
    WhiteCard,
    Stepper
  },
  data () {
    return {
      detailInfo: {},
      loading: {
        list: false
      },
      currId: '',
      favoriteList: []
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'loginForm']),
    ...mapState('payment', ['defaultCountryInfo']),
    ...mapState('cart', ['cartLength']),
    ...mapGetters('sys', ['withoutShoppingCartMode']),

    favoriteBgImageUrl () {
      const { gameCode } = getVersionByParseUrl()
      let img = ''
      try {
        img = require(`@/assets/favorite/fav-${gameCode}.jpg`)
      } catch (error) {
        // 兜底图。避免新增版本忘记配置时图片异常
        img = require('@/assets/favorite/fav-default.jpg')
      }
      return img
    },
    isEmptyList () {
      return !this.favoriteList?.length
    },
    currFavoriteItem () {
      const { currId, getCurrItem } = this
      return getCurrItem(currId)
    }
  },
  methods: {
    ...mapActions('favorite', ['getFavoriteList']),

    clickBuyByDetailPop ({ products }) {
      sendFavAndRecommandTrack({ eventName: 'buy_by_fav', products })
      OperatorRecord.setRecord({ products })
    },
    getCurrItem (favId) {
      const { favoriteList } = this
      return favoriteList.find((item) => item.id === favId) || {}
    },

    /**
     * @description: 将收藏夹添加、减少购物车
     * @param {*} favId 收藏夹id
     * @param {*} mode: 1加；-1减
     * @return {Promise:boolean} true 成功；其他表示失败
     */
    async updateCart ({ favId, mode = 1 }) {
      let { count, id: favorite_group_id } = this.getCurrItem(favId)
      count = count * mode
      return await validateBeforeUpdateBatchCartCount({
        mode,
        count,
        favorite_group_id
      })
    },
    // 详情弹窗 - 加入购物车按钮回调
    async addCartByDetailPop (favId) {
      const updateState = await this.updateCart({ favId, mode: 1 })

      if (updateState === true) {
        const { products } = this.detailInfo
        sendTrackAddCartCommon({ products, touch_type: 'favorite' })
        // sendFavAndRecommandTrack({ eventName: 'add_cart_by_fav', products })
        OperatorRecord.setRecord({ products })
        // 加购成功
        this.$router.push('/cart')
      }
    },
    /**
     * @description: 步进器的+ - 按钮回调
     * @param {*} num 目前数量
     * @param {*} item 操作的收藏夹item
     */
    async changeAmount (num, item) {
      const { quantity, id: favId } = item
      // 是否是加入
      const isAdd = num > quantity
      const mode = isAdd ? 1 : -1
      const updateState = await this.updateCart({ favId, mode })

      if (updateState === true) {
        item.quantity = num
        if (isAdd) {
          const { products } = await this.getDetail(favId)
          sendTrackAddCartCommon({ products, touch_type: 'favorite' })
          // sendFavAndRecommandTrack({ eventName: 'add_cart_by_fav', products })
          OperatorRecord.setRecord({ products })
        }
      }
    },
    async setDetailInfo (group_id) {
      this.detailInfo = await this.getDetail(group_id)
    },
    getDetail (group_id) {
      const { role_id } = this.userInfo
      return Apis.getFavoriteDetail({
        role_id,
        group_id
      })
    },
    async openDetailPop (group_id) {
      this.currId = group_id
      await this.setDetailInfo(group_id)
      this.$refs.detailPopRef.showPopup()
    },
    async deleteApi (group_id) {
      const { role_id } = this.userInfo

      await Apis.delFavorite({ group_id, role_id })
      this.$toast(this.$t('fav.cancelFavTip'))
      this.getFavorites()
    },
    openEditPop (item) {
      this.currId = item.id
      this.$refs.editPopRef.showPopup()
    },
    async getFavorites () {
      this.loading.list = true
      const { groups = [] } = await this.getFavoriteList().finally(() => {
        this.loading.list = false
      })

      groups.forEach((item) => {
        item.quantity = 0
      })
      this.favoriteList = groups
    }
  },
  created () {
    this.EDIT_TYPE_ENUM = EDIT_TYPE_ENUM
    this.getFavorites()
  }
}
</script>

<style lang="less" scoped>
@import url("~@/styles/mixin.less");

/deep/.custom-image {
  margin-top: 300px;
  .van-empty__image {
    width: 400px;
    height: 310px;
  }
}

.empty-text {
  text-align: center;
  font-size: 30px;
  line-height: 45px;
  color: #848484;
}
.list {
  // margin: 18px 12px;
  padding: 0 14px;
}
.item-title {
  font-size: var(--dp-text-md);
  line-height: 1.32;
  img {
    width: 28px;
    height: 28px;
    vertical-align: text-top;
    margin-left: 4px;
  }
}
.fav-item-bg {
  margin-top: var(--dp-sp-xl);
  padding: 0 26px;
  background-color: #e5ecf2;
  width: 100%;
  height: 180px;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  .icon-check {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 12px;
    top: 12px;
    background-image: url("../../assets/icon/icon_check.svg");
    background-size: 100%;
  }
}
.price-style {
  margin: 34px 0 28px 0;
  font-size: var(--dp-text-2xl);
  font-weight: 700;
  color: var(--dp-error-tertiary);
}
.favorite-stepper {
  /deep/ .van-stepper__input {
    color: var(--dp-text-tertiary);
  }
}
</style>
